import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export type PageType = { name: string, markdown: string, url: string, index: number }

const context = require.context("./pages/", true, /.md/);
const pages = context
  .keys()
  .reduce((prev, curr) => [...prev, {
    markdown: require(`./pages/${curr.replace("./", "")}`).default,
    name: curr.replace("./", "").split('-')[1].replace(".md", "").replaceAll("_", " "),
    index: parseInt(curr.replace("./", "").split('-')[0]),
    url: curr.replace("./", "").split('-')[1].toLowerCase().replace(".md", "").replaceAll("_", "-"),
  }], [] as PageType[])
  .sort();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App pageList={pages} />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

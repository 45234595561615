import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Routes, Route, Navigate } from "react-router-dom";
import { PageType } from '.';
import Navigation from './Navigation';

const Markdown = ({ page: { markdown, name } }: { page: PageType }) =>
  <div className='content-container'>
    <h3>{name}</h3>
    <ReactMarkdown children={markdown} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
  </div>

export default function App({ pageList }: { pageList: PageType[] }) {
  return (
    <Navigation links={pageList}>
      <Routes>
        {pageList.map((page, index) =>
          <Route key={index} path={page.url} element={<Markdown page={page} />} />
        )}
        <Route path="/" element={<Navigate replace to={pageList[0].url} />} />
      </Routes>
    </Navigation>
  );
}
